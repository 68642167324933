



















/* eslint-disable */
import { useState } from '@u3u/vue-hooks'
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'step-one',
  props: {
    parentData: {
      type: Object,
      required: true,
    },
  },

  setup(_props, ctx) {
    const { user, chrome } = useState(['user', 'chrome'])
    const { i18n, i18nSuivi} = useState('my', ['i18n','i18nSuivi'])
    const ZERO = ref('0')
    const ELEVEN = ref('11')
    const ONE = ref('1')
    //ScrollTop
    ctx.root.$nextTick(() => {
      const el = document.querySelector('.step-one')
      if (el) {
        el.scrollTop = el.scrollHeight
      }
      window.scroll({
        top: 0,
        behavior: 'smooth',
      })
    })

    return {
      user,
      chromeI18n: chrome.value.data.i18n,
      i18nSuivi,
      i18n,
      ZERO,
      ELEVEN,
      ONE
    }
  },
})
